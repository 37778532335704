import React, { useEffect, useRef, useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useSnackbar } from "notistack";
import { BUY_API } from "../../../constants/ApiPaths";
import axios from "../../../utils/axios";
import { useDispatch } from "../../../redux/store";
import { updateConditionSummary } from "../../../redux/slices/buy";
import Loader from "../../../components/Loader";

const PhNeedsModal = ({ row, currentBuy, conditionSummary }) => {
    const [units, setUnits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editedCellData, setEditedCellData] = useState(null);
    const [data, setData] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const gridRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${BUY_API.PH_NEEDS}?buy=${currentBuy?.id}&condition_code=${row.id}`)
            .then(r => {
                setData(r.data);
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                console.log('Error:', e);
            });
    }, [currentBuy.id, row.id]);

    const onGridReady = (params) => {
        gridRef.current = params.api;
        params.api.sizeColumnsToFit();
    };

    const onCellEditingStarted = (event) => {
        console.log('Cell Editing Started:', event);
    };

    const onCellEditingStopped = async (event) => {
        console.log('Cell Editing Stopped:', event);
        if (event.newValue !== event.oldValue) {
            const pharmacyId = parseInt(event.column.colId.replace('ph_', ''))
            const pharmacy = event.data.pharmacy.find((p) => p.pharmacy_id === pharmacyId);

            const payload = {
                id: pharmacy?.need_id,
                buy_id: currentBuy?.id,
                pharmacy: pharmacyId,
                catalog: event.data.product_id,
                update_units: event.newValue,
            };
            console.log({ payload });
            try {
                await axios.post(`/api/buy/need/adjust-need/`, payload);
                enqueueSnackbar('Units updated successfully!', {
                    variant: 'success',
                });
            } catch (error) {
                console.error('Error updating units:', error);
                enqueueSnackbar('Oops! Something went wrong.', {
                    variant: 'error',
                });
            }
        }
    };

    const columnDefs = useMemo(() => {
        const baseColumns = [
            { field: 'ean', headerName: 'EAN', width: 135 },
            { field: 'cn', headerName: 'CN', width: 100 },
            { field: 'product_name', headerName: 'Product Name', width: 250 },
            { field: 'total_units', headerName: 'Total Units', width: 100 },
        ];

        const pharmacyIds = new Set();
        const pharmacyNamesMap = {};

        data.forEach((item) => {
            item.pharmacy.forEach((pharmacy) => {
                pharmacyIds.add(pharmacy.pharmacy_id);
                pharmacyNamesMap[pharmacy.pharmacy_id] = pharmacy.pharmacy_name;
            });
        });

        const dynamicColumns = Array.from(pharmacyIds)
            .sort((a, b) => a - b)
            .map((id) => ({
                headerName: `Ph ${id}`,
                field: `ph_${id}`,
                headerTooltip: pharmacyNamesMap[id],
                valueGetter: (params) => {
                    const pharmacy = params.data.pharmacy.find(
                        (p) => p.pharmacy_id === id
                    );
                    return pharmacy ? pharmacy.units : 0;
                },
                valueSetter: (params) => {
                    const pharmacy = params.data.pharmacy.find(
                        (p) => p.pharmacy_id === id
                    );
                    if (pharmacy) {
                        pharmacy.units = params.newValue;
                        return true;
                    }
                    return false;
                },

                editable: true,
                cellEditor: 'agNumberCellEditor',
                width: 50,
                cellEditorParams: {
                    useFormatter: true,
                    popupPosition: 'under',
                },
                cellStyle: (params) => {
                    if (params.value !== 0) {
                        return {
                            backgroundColor: 'rgba(106, 220, 178, 0.24)',
                            textAlign: 'center',
                            boxShadow: 'inset 0 0 0 2px white',
                        };
                    } else {
                        return {
                            textAlign: 'center',
                            boxShadow: 'inset 0 0 0 2px transparent',
                        };
                    }
                },
            }));

        return [...baseColumns, ...dynamicColumns];
    }, [data]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div className="ag-theme-quartz">
                    <AgGridReact
                        domLayout="autoHeight"
                        rowData={data}
                        columnDefs={columnDefs}
                        enableBrowserTooltips={true}
                        onGridReady={onGridReady}
                        ref={gridRef}
                        defaultColDef={{
                            resizable: true,
                            suppressKeyboardEvent: (params) => {
                                return params.editing && ['ArrowRight', 'ArrowLeft'].includes(params.event.key);
                            },
                        }}
                        onCellEditingStarted={onCellEditingStarted}
                        onCellEditingStopped={onCellEditingStopped}
                        stopEditingWhenCellsLoseFocus={true}
                    />
                </div>
            )}
        </>
    );
};

export default PhNeedsModal;