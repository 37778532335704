import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { enqueueSnackbar, useSnackbar } from 'notistack';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AgGridReact, useGridFilter } from "ag-grid-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Snackbar, Alert, Tab } from "@mui/material";
import Container from '@mui/material/Container';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { Typeahead } from "react-bootstrap-typeahead";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from '../../utils/axios';
import useLocales from "../../hooks/useLocales";
import Iconify from "../../components/Iconify";
import LoadingScreen from "../../components/LoadingScreen";
import useSettings from "../../hooks/useSettings";
import { useDispatch, useSelector } from "../../redux/store";
import { PATH_BUY } from "../../routes/paths";
import { BUY_API } from "../../constants/ApiPaths";
import { getBuyCategories, getBuyConditions, getBuyProducts } from "../../redux/slices/buy";
import BuyInfo from "../../sections/buy/components/BuyInfo";
import Page from "../../components/Page";
import { fCurrency } from "../../utils/formatNumber";


// const BASE_URL = "http://63.33.131.240:8000";
const disableEditingHOC = (WrappedComponent) => {
    return (props) => {
        return props.data.name ? <WrappedComponent {...props} /> : <></>;
    };
};
const TypeAheadComponent = ({ options, data, changeCategory, ...rest }) => {
    const [singleSelections, setSingleSelections] = useState([]);
    const [gridApi, setGridApi] = React.useState(() => { })

    const updateTable = (value) => {
        const row = rest.api.getRowNode(data.id)
        row.updateData({
            ...data, categoryName: value.name, subCategory: "", subCategoryId: null, category: value.id,
            categoryId: value.id
        });
    }
    useEffect(() => {
        if (data?.id && data?.categoryName) setSingleSelections([{ id: data.id, name: data.categoryName }]);
    }, [data])
    const typeaheadRef = useRef();
    const fetchChangeCategory = (value, category, isEnter = false) => {
        updateTable(value)
        if (category && !isEnter) {
            rest.api.startEditingCell({
                colKey: "subCategory",
                rowIndex: rest.node.rowIndex
            });
        }
        axios.patch(`${BUY_API.Add_BUY_PRODUCT}${data.id}/`, {
            category: value.id
        }).then(r => {
            changeCategory(data.id, value.id, value.name, category, true);
            enqueueSnackbar('Product has been updated successfully.', { variant: 'success' });
        }).catch(e => {
            enqueueSnackbar('Failed to update the product.', { variant: 'error' });
        });
    }
    const onChange = (value) => {
        setSingleSelections(value);
        value = value[0];
        if (value?.name !== data.categoryName && value?.id) {
            rest.api.stopEditing(true);
            fetchChangeCategory(value)
        }
    }
    const onBlurHandle = (e, isEnter = false) => {
        const names = options.map(opt => opt.name);
        if (e.target.value.trim()) {
            if (!names.includes(e.target.value)) {
                axios.post(`${BUY_API.BUY_CATEGORIES}`
                    , { buy: data.buy, name: e.target.value, parent: null, children: [] }).then(r => {
                        rest.api.stopEditing(true);
                        fetchChangeCategory({ id: r.data.id, name: e.target.value }, r.data, isEnter)
                        enqueueSnackbar('Category has been added successfully.');
                    }).catch(e => {

                        enqueueSnackbar('Oops something went wrong.', {
                            variant: 'error',
                        });
                    });
            }
            else {
                const value = options.find(opt => opt.name === e.target.value.trim())
                if (value?.name !== data.categoryName && value?.id) {
                    fetchChangeCategory(value, undefined, isEnter)
                }
            }
        }
    }
    useEffect(() => {
        if (typeaheadRef.current) {
            typeaheadRef.current?.focus()
        }
    }, []);
    const onEnterPressed = (e) => {
        if (e.key === "Enter") {
            onBlurHandle(e, true);
        }
    }
    return <Typeahead
        positionFixed
        ref={typeaheadRef}
        multiple={false}
        labelKey={"name"}
        id="basic-typeahead-single"
        onChange={onChange}
        options={options}
        onBlur={onBlurHandle}
        onKeyDown={onEnterPressed}
        onKeyUp={onEnterPressed}
        placeholder="Choose a category"
        selected={singleSelections}
    />
}
const TypeAheadCondition = forwardRef(({ options, data, changeCondition, ...rest }, ref) => {
    const [singleSelections, setSingleSelections] = useState([]);
    useEffect(() => {
        if (data?.catalog_condition && data?.name) {
            setSingleSelections([{ id: data.catalog_condition, name: data.catalog_conditionName }])
        }
    }, [data])
    const typeaheadRef = useRef();
    const disableFocus = () => {
        if (typeaheadRef.current) {
            typeaheadRef.current?.blur()
            rest.api.stopEditing(true);
        }
    }
    const fetchChangeCategory = (value, condition = false) => {
        disableFocus();
        axios.patch(`${BUY_API.Update_BUY_PRODUCT}${data.id}/`, {
            catalog_condition: value.id
        }).then(r => {
            changeCondition(data.id, value.id, value.name, condition);
            enqueueSnackbar('Product has been updated successfully.', { variant: 'success' });
        }).catch(e => {
            enqueueSnackbar('Failed to update the product.', { variant: 'error' });
        });
    }

    const onChange = (value) => {
        setSingleSelections(value);
        value = value[0];
        if (value?.name !== data.categoryName && value?.id) {
            fetchChangeCategory(value)
        }
    }

    const onBlurHandle = (e) => {
        const names = options.map(opt => opt.name);
        if (e.target.value) {
            if (!names.includes(e.target.value)) {
                axios.post(`${BUY_API.BUY_ORDER_CONDITION}`
                    , {
                        buy: data.buy, name: e.target.value, condition_1: "1-0",
                        condition_2: "",
                        campaign_condition: "",
                        campaign_start_date: null,
                        campaign_end_date: null,
                        annual: null,
                    }).then(r => {
                        fetchChangeCategory({ id: r.data.id, name: e.target.value }, r.data)
                        enqueueSnackbar('Condition has been Created successfully.');
                    }).catch(e => {

                        enqueueSnackbar('Oops something went wrong.', {
                            variant: 'error',
                        });
                    });
            }
            else {
                const value = options.find(opt => opt.name === e.target.value.trim())
                if (value?.name !== data.categoryName && value?.id) {
                    fetchChangeCategory(value)
                }
            }
        }
    }
    useEffect(() => {
        if (typeaheadRef.current) {
            typeaheadRef.current?.focus()
        }
    }, []);
    const onEnterPressed = (e) => {
        if (e.key === "Enter") {
            onBlurHandle(e);
        }
    }
    return <Typeahead
        positionFixed
        multiple={false}
        ref={typeaheadRef}
        labelKey={"name"}
        id="basic-typeahead-single"
        onChange={onChange}
        options={options}
        onBlur={onBlurHandle}
        onKeyDown={onEnterPressed}
        onKeyUp={onEnterPressed}
        placeholder="Choose a category"
        selected={singleSelections}
    />
})
const changeProductData = async (productId, productColumn, newValue) => {
    try {
        await axios.patch(`${BUY_API.Update_BUY_PRODUCT}${productId}/`, {
            [productColumn]: newValue
        });
        enqueueSnackbar('Product has been updated successfully.', { variant: 'success' });
    } catch (error) {
        enqueueSnackbar('Failed to update the product.', { variant: 'error' });
    }
}
const changeConditionData = async (productId, productColumn, newValue) => {
    try {
        await axios.patch(`${BUY_API.BUY_ORDER_CONDITION}${productId}/`, {
            [productColumn]: newValue
        });
        enqueueSnackbar('Condition has been updated successfully.', { variant: 'success' });
    } catch (error) {
        enqueueSnackbar('Failed to update the Condition.', { variant: 'error' });
    }
}

const TypeSubComponent = ({ data, changeCategory, ...rest }) => {
    const disableFocus = () => {
        if (typeaheadRef.current) {
            typeaheadRef.current?.blur()
            rest.api.stopEditing(true);
        }
    }
    useEffect(() => {
        if (!data?.categoryName) {
            disableFocus()
        }
    }, [data?.categoryName])

    const [options, setOptions] = useState([]);
    const [singleSelections, setSingleSelections] = useState(data.subCategory ? [{ id: data.subCategoryId, name: data.subCategory }] : []);
    const fetchChangeSubCategory = (value) => {
        axios.patch(`${BUY_API.Update_BUY_PRODUCT}${data.id}/`, {
            category: value.id
        }).then(r => {
            changeCategory(data.id, value.id, value.name);
            const row = rest.api.getRowNode(data.id);
            row.setDataValue("subCategory", value.name)
            row.setDataValue("subCategoryId", value.id);
            enqueueSnackbar('Product has been updated successfully.');
        }).catch(e => {
            enqueueSnackbar('Oops something went wrong.', {
                variant: 'error',
            });
        });
    }
    const typeaheadRef = useRef();

    const onChange = (value) => {
        setSingleSelections(value);
        value = value[0]
        const names = options.map(opt => opt.name);
        if (names.includes(value?.name) && value?.name !== data.subCategory) {
            disableFocus()
            fetchChangeSubCategory(value);
        }
    }
    useEffect(() => {
        if (data.categoryId) {
            axios.get(`${BUY_API.BUY_CATEGORIES}?parent=${data.categoryId}`).then(res => {
                setOptions(res.data);
            })
        }
    }, [data.categoryId]);
    const onBlurHandle = (e) => {
        const names = options.map(opt => opt.name);
        if (e.target.value) {
            if (!names.includes(e.target.value)) {
                axios.post(`${BUY_API.BUY_CATEGORIES}`,
                    { buy: data.buy, name: e.target.value, parent: data.categoryId, children: [] }).then(r => {
                        disableFocus();
                        fetchChangeSubCategory({ id: r.data.id, name: r.data.name });
                        enqueueSnackbar('Category has been added successfully.');
                    }).catch(e => {

                        enqueueSnackbar('Oops something went wrong.', {
                            variant: 'error',
                        });
                    });
            }
            else {
                const value = options.find(opt => opt.name === e.target.value.trim());
                if (names.includes(value?.name) && value?.name !== data.subCategory) {
                    disableFocus();
                    fetchChangeSubCategory(value);
                }
            }
        }
    }
    useEffect(() => {
        if (typeaheadRef.current) {
            typeaheadRef.current?.focus()
        }
    }, []);
    const onEnterPressed = (e) => {
        if (e.key === "Enter") {
            onBlurHandle(e);
        }
    }
    return <Typeahead
        ref={typeaheadRef}
        positionFixed
        id="basic-typeahead-single"
        onChange={onChange}
        onBlur={onBlurHandle}
        onKeyDown={onEnterPressed}
        onKeyUp={onEnterPressed}
        options={options}
        labelKey={"name"}
        placeholder="Choose a category"
        selected={singleSelections}
    />
};
const CustomFilter = ({ defaultOptions, onModelChange, model, mainKey, currentBuy, ...rest }) => {
    const [options, setOptions] = useState(defaultOptions)
    useEffect(() => {
        if (mainKey === "subCategory") {
            axios.get(`${BUY_API.BUY_CATEGORIES}?buy=${currentBuy}&parent_isnull=false`).then(res => {
                setOptions(res.data);
            })
                .catch(e => console.log('e:', e));
        }
    }, [mainKey])
    const typeaheadRef = useRef()
    const [singleSelections, setSingleSelections] = useState([]);
    const doesFilterPass = useCallback(
        (params) => {
            const { node } = params;
            if (singleSelections.length === 0 && typeaheadRef?.current?.value?.trim() === "") return true
            if (mainKey === "condition") return node.data.catalog_conditionName === model
            if (mainKey === "categoryName") return node.data.categoryName === model
            if (mainKey === "subCategory") return node.data.subCategory === model
        },
        [model, singleSelections, mainKey],
    );
    const afterGuiAttached = useCallback((params) => {
        if (!params || !params.suppressFocus) {
            typeaheadRef?.current?.focus();
        }
    }, []);
    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });
    useEffect(() => {
        if (typeaheadRef.current) {
            typeaheadRef.current?.focus()
        }
    }, []);
    const onChange = (value) => {
        setSingleSelections(value);
        value = value[0];
        if (value?.name && value?.id) {
            onModelChange(value.name)
        }
        if (!value?.name?.trim()) {
            onModelChange(null)
        }
    }
    const onEnterPressed = (e) => {
        if (e.key === "Enter") {
            if (e?.target?.value?.trim()) {
                onModelChange(e.target.value)
            }
            else onModelChange(null)
        }
    }
    return <Typeahead
        positionFixed
        ref={typeaheadRef}
        multiple={false}
        labelKey={"name"}
        id="basic-typeahead-single"
        onChange={onChange}
        options={options}
        onKeyDown={onEnterPressed}
        onKeyUp={onEnterPressed}
        placeholder="Choose a category"
        selected={singleSelections}
    />
}
const CatalogProduct = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { themeStretch, themeMode } = useSettings();
    const [gridApi, setGridApi] = React.useState(() => { })
    const [gridApi2, setGridApi2] = React.useState(() => { })

    // Snackbar state
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });

    const onGridReady = (params) => {
        setGridApi(params.api);
    }
    const onGridReady2 = (params) => {
        setGridApi2(params.api);
    }
    const dispatch = useDispatch();
    const { buys } = useSelector((state) => state.buy);
    const { pathname, state } = useLocation();
    const createConditionThunk = async (name, buy) => {
        try {
            const res = await axios.post(`${BUY_API.BUY_CONDITION}`, {
                name, buy,
                "condition_1": "1-0"
            })
            if (res.data) {
                setData(prev => {
                    return prev.map(el => {
                        if (el.isBlanked) {
                            return res.data
                        }
                        return el
                    })
                });
            }
        }
        catch (e) {
            console.log('e:', e);
        }
    }
    const createProductThunk = async (name, buy) => {
        try {
            const res = await axios.post(`${BUY_API.Add_BUY_PRODUCT}`, { name, buy });
            if (res.data) {
                setSecondData(prev => prev.map(el => {
                    if (el.isBlanked) {
                        return { ...el, isBlanked: false, ...res.data }
                    }
                    return el
                }))
            }
        }
        catch (e) {
            console.log('e:', e);
        }
    }
    const navigate = useNavigate();
    const { catalog } = useSelector((state) => state.buy);
    const [data, setData] = useState([])
    const [currentBuy, setCurrentBuy] = useState();
    const [loadingBuy, setLoadingBuy] = useState(true);

    const [productLoaderFunction, setProductLoaderFunction] = useState(null);
    const { id = "" } = useParams()
    const [loadedCount, setLoadedCount] = useState(0);
    const getRowId = useCallback((params) => {
        return params.data.id;
    }, []);
    const increaseLoadedCount = () => setLoadedCount(prev => prev + 1);
    useEffect(() => {
        axios.get(`${BUY_API.Add_BUY_PRODUCT}catalog-conditions?buy=${id}`).then(res => {
            setData(res.data);
            increaseLoadedCount();
        }).catch(r => { })
    }, []);
    const [secondData, setSecondData] = useState([]);
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        axios.get(`${BUY_API.Add_BUY_PRODUCT}catalog-products/?buy=${id}`).then(res => {
            setSecondData(res.data);
            increaseLoadedCount();
        }).catch(r => { })
    }, []);
    useEffect(() => {
        axios.get(`${BUY_API.BUY_CATEGORIES}?buy=${id}&parent_isnull=${true}`).then(res => {
            setCategories(res.data);
            increaseLoadedCount()
        }).catch(r => { })
    }, []);
    const [isInit, setIsInit] = useState(true);
    useEffect(() => {
        if (secondData.length && data.length && categories.length && isInit) {
            setIsInit(false);
            setSecondData(prev => prev.map(product => {
                data.forEach(el => {
                    if (el.id === product.catalog_condition) product.catalog_conditionName = el.name
                });
                categories.forEach(c => {
                    if (c.id === product.category || c.id === product.categoryId) {
                        product.categoryName = c.name
                        product.categoryId = c.id
                        product.subCategory = null
                        product.subCategoryId = null
                    }
                    else {
                        c.children.forEach(subCategory => {
                            if (subCategory.id === product.category) {
                                product.subCategory = subCategory.name;
                                product.subCategoryId = subCategory.id
                                product.categoryName = c.name
                                product.categoryId = c.id
                            }
                        })
                    }

                });
                return { ...product, ean: Number(product.ean), pvl: Number(product.pvl), pvr: Number(product.pvr), pvr_tax: Number(product.pvr_tax) }
            }))
        }
    }, [data.length, secondData.length, categories.length]);
    const changeCategory = (productId, categoryId, categoryName, category = false, isAdd = false) => {
        setSecondData(prev => prev.map(el => {
            if (el.id === productId) {
                return {
                    ...el, categoryName, subCategory: "", subCategoryId: null,
                    categoryId
                }
            }
            return el
        }));
        if (isAdd && category) {
            setCategories(prev => ([...prev, category]));
        }
    }

    const changeSubCategory = (productId, categoryId, categoryName, isAdd = false) => {
        setSecondData(prev => prev.map(product => {
            if (product.id === productId) {
                return { ...product, subCategory: categoryName, subCategoryId: categoryId }
            }
            return product
        }));
    }

    const changeCondition = (productId, conditionId, newConditionName, createdCondition = false) => {
        if (createdCondition) {
            setIsInit(false);
            setData(prev => ([...prev, createdCondition]));
        }
        setSecondData(prev => prev.map(product => {
            if (product.id === productId) {
                return { ...product, catalog_conditionName: newConditionName, catalog_condition: conditionId }
            }
            return product
        }));


    }
    const { translate } = useLocales();
    const [showContent, setShowContent] = useState(true);
    const [showContent2, setShowContent2] = useState(true);
    const toggleShowContent = () => setShowContent(prevState => !prevState);
    const toggleShowContent2 = () => setShowContent2(prevState => !prevState);
    const suppressEnter = (params) => {
        const KEY_ENTER = "Enter";
        const key = params.event.key;
        return key === KEY_ENTER && params.editing === true;
    }
    const deleteProductHandle = async (productId) => {
        try {
            if (productId) {
                await axios.delete(`${BUY_API.Add_BUY_PRODUCT}${productId}/`)
                setSecondData(prev => prev.filter(el => el.id !== productId))
                enqueueSnackbar('Product has been deleted successfully.');
            }
            else {
                setSecondData(prev => prev.filter(el => !el.isBlanked));
            }
        }
        catch (e) {
            console.log('e', e);
            enqueueSnackbar('Oops something went wrong.', {
                variant: 'error',
            });
        }

    }
    const deleteConditionHandle = async (conId) => {
        try {
            if (conId) {
                await axios.delete(`${BUY_API.BUY_ORDER_CONDITION}${conId}/`);
                setData(prev => prev.filter(el => el.id !== conId));
                setSecondData(prev => prev.filter(el => el.catalog_condition !== conId))
                enqueueSnackbar('Condition has been deleted successfully.');
            }
            else {
                setData(prev => prev.filter(el => !el.isBlanked));
            }

        }
        catch (e) {
            console.error('err:', e);

            enqueueSnackbar('Oops something went wrong.', {
                variant: 'error',
            });
        }

    }

    useEffect(() => {
        if (id) {
            if (buys && buys.length) {
                const foundBuy = buys.find((buy) => buy.id === parseInt(id, 10));
                if (!foundBuy) navigate(PATH_BUY.buyList, { replace: true });
                setCurrentBuy(foundBuy);
                setLoadingBuy(false);
            }
            else {
                axios
                    .get(`${BUY_API.BUY}${id}/`)
                    .then((response) => {
                        setCurrentBuy({ ...response.data });
                        setLoadingBuy(false);
                    })
                    .catch((error) => {
                        setLoadingBuy(false);
                        navigate(PATH_BUY.buyList, { replace: true });
                    });
            }
            dispatch(getBuyCategories(id));
            // Load Catalog Conditions for current buy
            dispatch(getBuyConditions(id));
            // Load Catalog Products for current buy
            dispatch(getBuyProducts(id));
        } else {
            navigate(PATH_BUY.buyList, { replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const booleanFilter = {
        filterOptions: [
            {
                displayKey: 'all',
                displayName: 'All',
                predicate: (_, cellValue) => true,
                numberOfInputs: 0,
            },
            {
                displayKey: 'true',
                displayName: 'True',
                predicate: (_, cellValue) => +cellValue === 1,
                numberOfInputs: 0,
            },
            {
                displayKey: 'false',
                displayName: 'False',
                predicate: (_, cellValue) => +cellValue === 0,
                numberOfInputs: 0,
            }
        ],
        suppressAndOrCondition: true,
    };
    const taxFilter = {
        filterOptions: [
            {
                displayKey: 'all',
                displayName: 'All',
                predicate: (_, cellValue) => true,
                numberOfInputs: 0,
            },
            {
                displayKey: '0%',
                displayName: '0%',
                predicate: (_, cellValue) => cellValue !== null && +cellValue === 0,
                numberOfInputs: 0,
            },
            {
                displayKey: '4%',
                displayName: '4%',
                predicate: (_, cellValue) => +cellValue === 4,
                numberOfInputs: 0,
            },
            {
                displayKey: '10%',
                displayName: '10%',
                predicate: (_, cellValue) => +cellValue === 10,
                numberOfInputs: 0,
            },
            {
                displayKey: '21%',
                displayName: '21%',
                predicate: (_, cellValue) => +cellValue === 21,
                numberOfInputs: 0,
            },
        ],
        suppressAndOrCondition: true,
    };

    const addNewCondition = () => {
        let isFound = false
        data.forEach(el => {
            if (el.isBlanked) {
                isFound = true
            }
        });
        if (!isFound) {
            setData(prev => ([{ isBlanked: true, buy: id }, ...prev]));
        }
    }
    const addNewProductForm = () => {
        let isFound = false
        secondData.forEach(el => {
            if (el.isBlanked) {
                isFound = true
            }
        });
        if (!isFound) {
            setSecondData(prev => ([{ isBlanked: true, buy: id, name: "" }, ...prev]));
        }
    }

    const autoSizeStrategy = {
        type: 'fitCellContents'
    };

    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <Page title="Listado de Compras">
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        <Tab label={translate("product")} value="1" />
                        <Tab label={translate("condition")} value="2" />
                    </TabList>
                </Box>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    {loadedCount === 3 && !loadingBuy ? <>
                        <TabPanel value="1">
                            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1, mt: 1 }}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={addNewProductForm}
                                >
                                    {translate("add")}
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="small"

                                    startIcon={<Iconify icon={showContent ? 'formkit:eyeclosed' : 'ph:eye'} />}
                                    onClick={toggleShowContent2}
                                >
                                    {showContent ? translate('hide') : translate('show')}
                                </LoadingButton>

                            </Box>
                            <div
                                className={themeMode === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}
                                style={{ width: "100%", overflow: "auto", marginBottom: "30px" }} >
                                <AgGridReact
                                    enableBrowserTooltips
                                    autoSizeStrategy={autoSizeStrategy}
                                    onGridReady={onGridReady}
                                    reactiveCustomComponents
                                    style={{ overflow: "auto !important", marginBottom: "30px" }}
                                    domLayout='autoHeight'
                                    rowData={secondData} columnDefs={[
                                        {
                                            field: "categoryName",
                                            filter: (props) => <CustomFilter mainKey={"categoryName"} currentBuy={id}
                                                defaultOptions={categories} {...props} />,
                                            headerName: translate("Categoria"),
                                            width: 70, // Set the width of the column here
                                            editable: true,
                                            cellEditor: (props) => {
                                                const Component = disableEditingHOC(TypeAheadComponent)
                                                return <Component
                                                    options={categories.map(c => ({ id: c.id, name: c.name }))}
                                                    changeCategory={changeCategory} {...props} />
                                            },
                                            enableCellChangeFlash: true,
                                            suppressHeaderKeyboardEvent: (params) => {
                                                const key = params.event.key;
                                                return key === "Tab";
                                            },
                                            cellEditorPopup: true,
                                            suppressKeyboardEvent: (params) => {
                                                return suppressEnter(params)
                                            },
                                        },
                                        {
                                            field: "subCategory",
                                            filter: (props) => <CustomFilter mainKey={"subCategory"} currentBuy={id} defaultOptions={[]} {...props} />,
                                            headerName: translate("Sub Cat"),
                                            width: 70,  // Set the width of the column here
                                            editable: true,
                                            cellEditorPopup: true,
                                            enableCellChangeFlash: true,
                                            cellEditor: (props) => {
                                                const Component = disableEditingHOC(TypeSubComponent)
                                                return <Component changeCategory={changeSubCategory} {...props} />
                                            },
                                            suppressKeyboardEvent: (params) => {
                                                return suppressEnter(params)
                                            },
                                            // Adding tooltip
                                            tooltipValueGetter: (params) => {
                                                // You can format or compute the tooltip string here based on cell data
                                                return params.value ? `Sub Category: ${params.value}` : "";
                                            }
                                        },
                                        {
                                            field: "catalog_conditionName",
                                            filter: (props) => <CustomFilter mainKey={"condition"} currentBuy={id} defaultOptions={data} {...props} />,
                                            editable: true,
                                            cellEditorPopup: true,
                                            enableCellChangeFlash: true,
                                            headerName: translate("Condicion"),
                                            width: 70, // Set the width of the column here
                                            suppressKeyboardEvent: (params) => {
                                                return suppressEnter(params)
                                            },
                                            cellEditor: (props) => {
                                                const Component = disableEditingHOC(TypeAheadCondition)
                                                return <Component options={data} changeCondition={changeCondition} {...props} />
                                            }
                                        },
                                        {
                                            field: "ean",
                                            headerName: translate("EAN"),
                                            width: 135, // Set the width of the column here
                                            editable: true, cellEditor: "agNumberCellEditor",
                                            filter: "agNumberColumnFilter",
                                            onCellValueChanged: async (value) => {
                                                if (!value.data.name) {
                                                    value.node.setDataValue(value.column.colId, "");
                                                    return;
                                                }
                                                console.log("val.new", value.newValue);
                                                if (value.newValue === null) {
                                                    value.node.setDataValue(value.column.colId, value.oldValue);
                                                    addNewProductForm()
                                                    return;
                                                } await changeProductData(value.data.id, "ean", String(value.newValue))
                                            },
                                            cellEditorParams: {
                                                min: 0,
                                                max: 9999999999999,
                                                precision: 0
                                            }
                                        },
                                        {
                                            field: "cn",
                                            headerName: translate("CN"),
                                            width: 100, // Set the width of the column here
                                            editable: true,
                                            cellEditor: "agTextCellEditor",
                                            filter: "agTextColumnFilter",
                                            onCellValueChanged: async (event) => {
                                                // if (event?.newValue?.trim()?.length === 0 || event.newValue === null) {
                                                //     event.node.setDataValue(event.column.colId, event.oldValue);
                                                //     addNewProductForm()
                                                //     return;
                                                // }
                                                const regex = /^\d{6}(\.\d)?$/; // Regex to allow "######" or "######.#"
                                                if (!regex.test(event.newValue)) {
                                                    event.node.setDataValue(event.column.colId, ''); // Clear the value
                                                    setSnackbar({ open: true, message: 'Invalid CN format. Please enter a 6-digit number optionally followed by a decimal and one more digit.' });
                                                } else {
                                                    // If valid, update the product data
                                                    await changeProductData(event.data.id, "cn", event.newValue);

                                                    setSnackbar({ open: false, message: '' }); // Optionally reset or provide a success message
                                                }
                                            },
                                            cellEditorParams: {
                                                minLength: 0,
                                                maxLength: 9
                                            }
                                        },
                                        {
                                            field: "min_units",
                                            filter: "agTextColumnFilter",
                                            headerName: translate("Ud Min"),
                                            width: 50, // Set the width of the column here
                                            editable: true,
                                            cellEditor: "agNumberCellEditor",
                                            onCellValueChanged: async (value) => {
                                                if (!value.data.name) {
                                                    value.node.setDataValue(value.column.colId, "");
                                                    return;
                                                }
                                                await changeProductData(value.data.id, "min_units", value.newValue)
                                            },
                                            cellEditorParams: {
                                                min: 0,
                                                precision: 0,
                                            }
                                        },
                                        {
                                            field: "units_inbox",
                                            headerName: translate("Caja"),
                                            width: 50, // Set the width of the column here
                                            filter: "agTextColumnFilter",
                                            editable: true,
                                            cellEditor: "agNumberCellEditor",
                                            onCellValueChanged: async (value) => {
                                                if (!value.data.name) {
                                                    value.node.setDataValue(value.column.colId, "");
                                                    return;
                                                }
                                                await changeProductData(value.data.id, "units_inbox", value.newValue)
                                            },
                                            cellEditorParams: {
                                                min: 0,
                                                precision: 0,
                                            }
                                        },
                                        {
                                            colKey: "name",
                                            field: "name",
                                            headerName: translate("Nombre"),
                                            width: 350, // Set the width of the column here
                                            editable: true,
                                            filter: "agTextColumnFilter",
                                            cellEditor: "agTextCellEditor",
                                            onCellValueChanged: async (event) => {
                                                if (event.data.isBlanked && !(event?.newValue?.trim()?.length === 0 || event.newValue === null)) {
                                                    await createProductThunk(event.newValue, event.data.buy);
                                                    return;
                                                }
                                                if (event?.newValue?.trim()?.length === 0 || event.newValue === null) {
                                                    event.node.setDataValue(event.column.colId, event.oldValue);
                                                    return;
                                                }
                                                await changeProductData(event.data.id, "name", event.newValue)
                                            },
                                            cellEditorParams: {
                                                minLength: 1,
                                                MaxLength: 500,
                                            }
                                        },
                                        {
                                            field: "pvl",
                                            headerName: translate("PVL"),
                                            width: 95, // Set the width of the column here
                                            filter: "agNumberColumnFilter",
                                            editable: true, cellEditor: "agNumberCellEditor",
                                            cellRenderer: (props) => fCurrency(props.value),
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeProductData(event.data.id, "pvl", event.newValue)
                                            },
                                            cellEditorParams: {
                                                precision: 4,
                                                min: -100000,
                                                max: 999999
                                            }
                                        },
                                        {
                                            field: "pvr",
                                            headerName: translate("PVP"),
                                            width: 95, // Set the width of the column here
                                            filter: "agNumberColumnFilter",
                                            editable: true, cellEditor: "agNumberCellEditor",
                                            cellRenderer: (props) => fCurrency(props.value),
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeProductData(event.data.id, "pvr", String(event.newValue))
                                            },
                                            cellEditorParams: {
                                                precision: 4,
                                                min: -100000,
                                                max: 999999
                                            }
                                        },
                                        {
                                            field: "pvr_tax", filter: "agNumberColumnFilter",
                                            headerName: translate("PVR"),
                                            width: 95, // Set the width of the column here
                                            editable: true, cellEditor: "agNumberCellEditor",
                                            cellRenderer: (props) => fCurrency(props.value),
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeProductData(event.data.id, "pvr_tax", String(event.newValue))
                                            },
                                            cellEditorParams: {
                                                precision: 4,
                                                min: -100000,
                                                max: 999999
                                            }
                                        },
                                        {
                                            field: "tax",
                                            headerName: translate("IVA"),
                                            editable: true,
                                            filter: 'agNumberColumnFilter',
                                            filterParams: taxFilter,
                                            valueFormatter: params => `${Math.floor(params.value)}%`,
                                            cellEditor: 'agSelectCellEditor',
                                            cellEditorParams: {
                                                values: [`0`, `4.0000`, `10.0000`, `21.0000`],
                                            },
                                            width: 70, // Set the width of the column here
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                try {
                                                    await axios.patch(`${BUY_API.Update_BUY_PRODUCT}${event.data.id}/`, {
                                                        tax: Math.floor(+event.newValue) / 100
                                                    })
                                                    enqueueSnackbar('Product has been updated successfully.', { variant: 'success' });
                                                } catch (error) {
                                                    enqueueSnackbar('Failed to update the product.', { variant: 'error' });
                                                }
                                            }
                                        },

                                        {
                                            field: "out_of_stock",
                                            headerName: translate("stock"),
                                            cellEditor: "agCheckboxCellEditor",
                                            editable: true,
                                            width: 40,  // Set the desired width here
                                            filter: 'agNumberColumnFilter',
                                            filterParams: booleanFilter,
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, undefined);
                                                    return;
                                                }
                                                await changeProductData(event.data.id, "out_of_stock", event.newValue);
                                            },
                                            cellRenderer: "agCheckboxCellRenderer",
                                        },

                                        {
                                            field: "id", headerName: "Delete",
                                            cellRenderer: props => {
                                                return (
                                                    <IconButton
                                                        onClick={() => deleteProductHandle(props.value)}
                                                        color="error"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                );
                                            }
                                        },

                                    ]} defaultColDef={{ sortable: true }}
                                    animateRows={false}
                                    getRowId={getRowId}
                                    stopEditingWhenCellsLoseFocus={"true"}
                                />
                            </div>
                        </TabPanel>

                        <TabPanel value="2">
                            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1, mt: 1 }}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={addNewCondition}
                                >
                                    {translate("add")}
                                </LoadingButton>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="small"

                                    startIcon={<Iconify icon={showContent ? 'formkit:eyeclosed' : 'ph:eye'} />}
                                    onClick={toggleShowContent}
                                >
                                    {showContent ? translate('hide') : translate('show')}
                                </LoadingButton>
                            </Box>
                            <div
                                style={{ width: "100%" }}
                                className={themeMode === "dark" ? "ag-theme-quartz-dark" : "ag-theme-quartz"}>
                                <AgGridReact onGridReady={onGridReady2} domLayout='autoHeight' getRowId={getRowId}
                                    rowData={data} columnDefs={[
                                        {
                                            field: "name", editable: true, cellEditor: "agTextCellEditor", cellEditorParams: {
                                                maxLength: 50,
                                                minLength: 1
                                            },
                                            enableCellChangeFlash: true,
                                            filter: "agMultiColumnFilter",
                                            onCellValueChanged: async (event) => {
                                                if (event.data.isBlanked) {
                                                    await createConditionThunk(event.newValue, event.data.buy);
                                                    return;
                                                }
                                                if (event?.newValue?.trim()?.length === 0 || event.newValue === null) {
                                                    event.node.setDataValue(event.column.colId, event.oldValue);
                                                    addNewCondition()
                                                    return;
                                                }
                                                await changeConditionData(event.data.id, "name", String(event.newValue))
                                                setData(prev => prev.map(condition => {
                                                    if (condition.id === event.data.id) {
                                                        return { ...condition, name: event.newValue }
                                                    }
                                                    return condition
                                                }));
                                                setSecondData(prev => prev.map(product => {
                                                    if (product.catalog_conditionName === event.oldValue) return { ...product, catalog_conditionName: event.newValue }
                                                    return product
                                                }))
                                                event.node.setDataValue(event.column.colId, event.newValue);
                                            }

                                        },
                                        {
                                            field: "annual", filter: "agTextColumnFilter", enableCellChangeFlash: true,
                                            headerName: translate("Anual"), editable: true, cellEditor: "agTextCellEditor", cellEditorParams: {
                                                maxLength: 255
                                            },
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeConditionData(event.data.id, "annual", String(event.newValue))
                                            }
                                        },
                                        {
                                            field: "condition_1", enableCellChangeFlash: true, filter: "agTextColumnFilter",
                                            headerName: translate("Surtido"), editable: true, cellEditor: "agTextCellEditor", cellEditorParams: {
                                                maxLength: 200,
                                                minLength: 1
                                            },
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                if (event?.newValue?.trim()?.length === 0 || event.newValue === null) {
                                                    event.node.setDataValue(event.column.colId, event.oldValue);
                                                    return;
                                                }
                                                await changeConditionData(event.data.id, "condition_1", String(event.newValue))
                                            }
                                        },
                                        {
                                            field: "condition_2", headerName: translate("Individual"), editable: true, cellEditor: "agTextCellEditor", cellEditorParams: {
                                                maxLength: 200,
                                            }, filter: "agTextColumnFilter",
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeConditionData(event.data.id, "condition_2", String(event.newValue))
                                            }
                                        },
                                        {
                                            field: "campaign_condition", headerName: translate("Campaña"), editable: true, cellEditor: "agTextCellEditor", cellEditorParams: {
                                                maxLength: 50,
                                            },
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeConditionData(event.data.id, "campaign_condition", String(event.newValue))
                                            }, enableCellChangeFlash: true, filter: "agTextColumnFilter",
                                        },
                                        {
                                            field: "campaign_start_date", headerName: translate("Fecha Inicio"), editable: true,
                                            valueGetter: (p) => {
                                                const init = p.data.campaign_start_date
                                                const currentDate = new Date(init)
                                                return init ? currentDate.toISOString().split('T')[0] : "";
                                            },
                                            cellEditor: "agDateCellEditor", cellEditorParams: {
                                                maxLength: 50,
                                            }, filter: "agDateColumnFilter",
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                const currentDate = new Date(event.newValue)
                                                const value = currentDate.toISOString().split('T')[0];
                                                await changeConditionData(event.data.id, "campaign_start_date", value);
                                            }
                                        },
                                        {
                                            field: "campaign_end_date", headerName: translate("Fecha Fin"), editable: true,
                                            valueGetter: (p) => {
                                                const init = p.data.campaign_end_date
                                                const currentDate = new Date(init)
                                                return init ? currentDate.toISOString().split('T')[0] : "";
                                            },
                                            filter: "agDateColumnFilter",
                                            cellEditor: "agDateCellEditor", cellEditorParams: {
                                                maxLength: 50,
                                            },
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                const currentDate = new Date(event.newValue)
                                                const value = currentDate.toISOString().split('T')[0];
                                                await changeConditionData(event.data.id, "campaign_end_date", value);


                                            }
                                        },
                                        {
                                            field: "note", headerName: translate("Notas"), editable: true, cellEditor: "agTextCellEditor",
                                            onCellValueChanged: async (event) => {
                                                if (!event.data.name) {
                                                    event.node.setDataValue(event.column.colId, "");
                                                    return;
                                                }
                                                await changeConditionData(event.data.id, "note", String(event.newValue))
                                            }, filter: "agTextColumnFilter",
                                        },
                                        {
                                            field: "id", headerName: "delete", cellRenderer: props => {
                                                return (
                                                    <IconButton
                                                        onClick={() => deleteConditionHandle(props.value)}
                                                        color="error"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                );
                                            }
                                        },
                                    ]} />
                            </div>
                        </TabPanel>
                        {/* Snackbar for showing messages */}
                        <Snackbar
                            open={snackbar.open}
                            autoHideDuration={6000}
                            onClose={() => setSnackbar({ ...snackbar, open: false })}
                            message={snackbar.message}
                            action={
                                <>
                                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbar({ ...snackbar, open: false })}>
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                </>
                            }
                        />
                    </> : <LoadingScreen />}
                </Container>
            </TabContext>
        </Page>
    );
};

export default CatalogProduct